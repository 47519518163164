import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, UsersGETApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import NotFound from "../../NotFound";
import FirstScreenResult from "../../Student/ExamResult/FirstScreen";
import store from '../../../redux/store';

export default function DailyResultDetails(props) {
  const [NotFoundData, setNotFound] = React.useState(false)
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});
  const [Rank, setRank] = React.useState(1);
  const location = useLocation()
  const history = useHistory()
  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID;
    console.log(id)
    AdsGETApiAuth({
      slug: slug,
    }, slugs.db_slug_daily_results)
      .then((response) => {
        if (response.data.length !== 0) {
          AdsGETApiAuth({
            "filter[meta_query][1][key]": "exam_id_results",
            "filter[meta_query][1][value][0]": `${response.data[0].meta.exam_id_results}`,
            "filter[meta_query][1][compare]": "=",
            "filter[meta_query][2][key]": "exam_total_results",
            "filter[meta_query][2][value][0]": `${response.data[0].meta.exam_total_results}`,
            "filter[meta_query][2][compare]": ">",
            _fields: "id"
          }, slugs.db_slug_daily_results)
            .then((r) => {
              setRank(r.data.length)
              console.log(r.data.length)
            })
          setChoose(2)
          setResultData(response.data[0])
          setAnswerArray(JSON.parse(response.data[0].meta.exam_answers_array_results))
          AdsGETApiAuth({}, `${slugs.db_slug_daily_exam}/${response.data[0].meta.exam_id_results}`)
            .then((res) => {
              setAllExamList(res.data)
              setExamFetch(true)
              console.log(JSON.parse(response.data[0].meta.exam_answers_array_results))
            })
            .catch(() => {
              setNotFound(true)
            })
            .finally(() => {
              setReload(false)
            })
        }
        else {
          setNotFound(true)
          setExamFetch(true)
          setReload(false)
        }
      })
      .catch(() => {
        setNotFound(true)
      })

  }
  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.daily_result_details.length ))
  }, [])
  return (
    <div>
    <div className="row">
      <div className="col-1">
      </div>
      <div className="col-10 form-view mt-3">
        {ExamFetch &&
          <>
            {NotFoundData ?
              <NotFound /> :
              <FirstScreenResult
                ExamData={ExamData}
                setReload={setReload}
                choose={choose}
                setChoose={setChoose}
                AnswerArray={AnswerArray}
                setAnswerArray={setAnswerArray}
                ResultData={ResultData}
                Rank={Rank}
              />
            }
          </>
        }
      </div>
    </div>
    {reload &&
      <div className="modal" style={{ padding: "50vw" }}>
        <div className="dot-pulse"></div>
      </div>
    }
  </div>
  )
}